html, body {
  background-color: #000000 !important;
  background-image: url('./component/assist/images/minting-bg.png');
  background-repeat: no-repeat;
  background-position: bottom;;
  background-size: cover;
  /* height: 100vh; */
  width: 100%;
}
/* .App {
  background-color: black;
  height: 100vh;
} */


.bg_img {
  background-image: url('./component/assist/images/nest-img.png');
  background-repeat: no-repeat;
  background-position: center;;
  background-size: cover;
  height: 100%;
}
.bg-color {
  background-color: #444444;
}
.bg-list {
background-color: #262626 !important;
}
.height_6 {height: 3rem !important;}
.border-color.border-0 {border-left: 1px solid #444 !important;border-right:1px solid #444 !important;background-color: transparent;}
.width-100 {
  width: 100%;
}
li.bg-transparent{
  border: none;
}
#nftMintAmt::-webkit-outer-spin-button,
#nftMintAmt::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

#PopupFormMembershipNo::-webkit-outer-spin-button,
#PopupFormMembershipNo::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}

.custom_header_container{
  max-width: 1012px !important;
  align-items: flex-start !important;
}

.custom_home_container{
  max-width: 1012px !important;
}

.custom_popup_container{
  max-width: 1012px !important;
  z-index: 9999;
}

.custom_footer_container{
  max-width: 1012px !important;
}

.navbar-brand{
  max-width: 250px;
  position: relative;
}

.header_logo{
  min-width: 250px
}

.nav_brand_text{
  padding-left: 10px;
  /* position: absolute;
  left: 50%;
  bottom: -10px;
  transform: translateX(-50%); */
}

.popUp_col{
  border: 1px #fff solid;
  position: relative;
}

div#RecaptchDiv div {
  max-width: 100% !important;
  width: 100% !important;
}

div#RecaptchDiv {
  overflow: hidden;
}

.close-icon{
  position: absolute;
  top: -2%;
  right: -2%;
  padding: 10px;
  background: #111;
  border-radius: 50%;
  height: 10px;
  width: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  cursor: pointer;
}

.progress-bar.bg-success{
  color: #000 !important;
  background-color: #33ffff !important;
}

.pieBar {
  color: #000 !important;
  background-color: #444;
  position: relative;
  height: 48px;
  display: flex;
  align-items: center;
}

.filler {
  position: absolute;
  /* width: 10%; */
  height: 100%;
  background: #33ffff;
}

.filler span {
  position: absolute;
  /* right: 0; */
  right: 0;
  top: -25px;
  color: #33ffff;
}

.pieBar > span {
  position: absolute;
  right: 0;
  top: -25px;
  color: #fff;
}

button:active {
  margin: 2px;
  -webkit-box-shadow: inset 0 0 0 white;
  -moz-box-shadow: inset 0 0 0 white;
  box-shadow: inset 0 0 0 white;
}

button.customBtn:active {
  margin: 0px;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}
.color-white{
  color:#ffffff;
}

.popUp_div{
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 999;
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,.90);
}

@media screen and (max-width:475px) {
  .navbar-brand{
    max-width: 300px;
    margin-left: auto !important;
    margin-right: auto !important;
  } 

  .custom_footer_container{
    display: flex;
    justify-content: center;
  }
  .custom_footer_ul{
    margin: auto;
  }
  .navbar-collapse{
    width: 100% !important;
    justify-content: center !important;
  }
  li.nav-item.d-flex.justify-content-between.bg-list.text-light.mb-3 {
    font-size: 12px;
    max-width: 100%;
  }
  li.nav-item.d-flex.justify-content-between.align-items-center.bg-list.text-light.py-2.px-3.mb-2.fw-bold {
    font-size: 12px;
    max-width: 100%;
  }
}

@media screen and (min-width: 992px) {
  .custom_row{
    display: table;
  }
  .custom_col{
    display: table-cell;
  }
  .custom_img{
    height: 450px !important;
  }
  .custom_col_content{
    min-height: 450px !important;
  }
}

.custom_img{
  width: 100%;
}

.email_validation{
  color: red;
}

.nav-item{
  max-width: 100%;
}

.navbar-collapse{
  justify-content: flex-end;
}

/* Firefox */

#nftMintAmt[type=number] {
-moz-appearance: textfield;
}

.App{
  position: relative;
}

.mint_loader{
  position: absolute;
  top: 0%;
  left: 0%;
  z-index: 99999;
  width: 100%;
  height: 100%;
  background-color: #262626a8;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader_logo{
  width: 200px;
  height: 200px;
  opacity: 0.8;
  position: relative;
  animation-name: loader;
  animation-duration: 4s;
  animation-iteration-count: infinite;
}

@keyframes loader {
  0%   { left:0px; top:0px;}
  25%  {transform: rotate(45deg);left:100px; top:0px;}
  50%  {transform: rotate(0deg);left:0px; top:0px;}
  75%  { transform: rotate(-45deg);left:-100px; top:0px;}
  100% {left:0px; top:0px;}
}

.customCss{
  align-items: center;
  align-self: center;
}

@media screen and (max-width: 767px) {
  .customHeight{
    min-height: 320px;
  }

}
.lds-spinner {
  color: official;
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-spinner div {
  transform-origin: 40px 40px;
  animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 3px;
  left: 37px;
  width: 6px;
  height: 18px;
  border-radius: 20%;
  background: #fff;
}
.lds-spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}
@keyframes lds-spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.customHeight.custom_padding {
  padding: 2.7rem!important;
}
.custom_lineheight {
  line-height: 0px;
}

.cursor-pointer {cursor: pointer;}

@media screen and (max-width: 600px) {
  h2.text-center.mb-4.fw-light {
    font-size: 20px;
    line-height: 28px;
  }
  input#nftMintAmt {
    min-width: 40px;
    max-width: 100%;
  }
  .next_ul li {
    font-size: 20px;
  }
  .next_ul li .bg-list {
    font-size: 16px !important;
    display: flex;
    align-items: center;
    justify-content: left;
    width: 100%;
    font-weight: 400 !important;
    padding: 6px !important;
  }
  .text-dark.text-uppercase.mint_submitBtn.rounded-pill{
    min-width: 200px;
    padding : 1rem !important;
  }
  a.buyEthLink {
    font-size: 16px !important;
  }
  .next_ul li .bg-list:last-child {
    font-size: 16px !important;
    display: flex;
    align-items: center;
    justify-content: end;
  }
  .customBtn {
    min-width: 25px;
    padding: 0 5px;
    text-align: center !important;
    justify-content: center !important;
  }
  .header_logo {
    min-width: 200px;
  }
  .py-5.nav_ss {padding-top: 0px !important;}
  .App{ padding : 20px;}
  .navbar-brand .text-start.fw-light.nav_brand_text {
    text-align: center !important;
    font-size: 20px;
  }
  .navbar-collapse.collapse .text-dark.text-uppercase.rounded-pill.py-3.px-4.fw-bold {
    min-width: 200px;
  }
  .pieBar {
    height: 40px;
  }
  .customHeight.custom_padding {
    padding: 0.7rem!important;
  }
  .customHeight {
    min-height: 370px;
  }
}
@media screen and (max-width: 320px) {
  .App{ padding : 15px;}
}